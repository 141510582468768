.modalListContainer {
  .subContainer {
    display: none;
  }

  &.open {
    .subContainer {
      display: block;
    }
  }
}

.border-transparent {
  border-color: transparent !important;
}

@for $i from 1 through 6 {
  $zIndexBackdrop: #{1000 + (5 * $i)};
  $zIndexContent: #{1000 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

.offcanvas-backdrop {
  z-index: 1000;
}

#logDetailsModal .modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}
