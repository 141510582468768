// FONT BASE
$font-size-base: .95rem !default;
// $font-family-sans-serif: Arial, sans-serif !default;
$font-family-sans-serif: 'Ubuntu', Helvetica, Arial, Lucida, sans-serif;

// COLORS
$primary: #8f0024 !default;
$secondary: #5A5A5A !default;
$light: #EDEDED !default;
$dark: #3E3E3E !default;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$border-color: $gray-400 !default;
$card-border-color: $gray-400 !default;

// HEADINGS
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 700 !default;
$headings-color: $dark;
// $h1-font-size: $font-size-base * 3.13 !default;
// $h2-font-size: $font-size-base * 2.75 !default;

// BORDER RADIUS
$border-radius: .2rem !default;
$border-radius-sm: .2rem !default;
$border-radius-lg: .2rem !default;
$border-radius-xl: .2rem !default;
$border-radius-2xl: .2rem !default;
$border-radius-pill: 50rem !default;

// $input-border-color: $card-border-color;

// INPUTS & BTN
$input-btn-padding-y: .375rem !default;
$input-btn-padding-x: .75rem !default;

// BTN
$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x * 2 !default;

// CONTAINER
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px
) !default;

// SPACER
$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 3.25,
        7: $spacer * 3.5,
        8: $spacer * 3.75,
        9: $spacer * 4,
        10: $spacer * 4.25,
) !default;


// SELECT
// $form-select-border-color: $primary;
// $form-select-border-radius: 2rem;
// $form-select-indicator-padding: 3rem;


