.pie-chart-tooltip {
    position: absolute;
    text-align: center;
    width: 120px;
    padding: 5px;
    font: 12px sans-serif;
    background: lightsteelblue;
    border: 0px;
    border-radius: 8px;
    pointer-events: none;
}
