#dataTable_wrapper {
  > div {
    &.top,
    &.bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        float: none;
        padding: $card-cap-padding-x / 1.5 $card-cap-padding-x;
      }
    }

    &.top > div {
      padding: $card-cap-padding-x $card-cap-padding-x 0;
    }
  }

  .main {
    #dataTable {
      tr {
        position: relative;
        background: $white;
        cursor: pointer;

        &:hover {
          background: $gray-200;

          .put-bg {
            background: aquamarine;
          }

          .post-bg {
            background: chocolate;
          }

          .delete-bg {
            background: gold;
          }
        }

        &.tr-active {
          td {
            background: rgba($primary, 0.75);
            color: $white;
          }
        }

        &.dt-hasChild {
          td {
            background: rgba($primary, 0.9);
            color: $white;
          }

          + tr td {
            padding: $grid-gutter-width / 2 $grid-gutter-width $grid-gutter-width;
            background: $gray-100;

            .sub_items {
              column-count: 1;

              @include media-breakpoint-up(md) {
                column-count: 2;
                column-gap: 30px;
                column-rule: 1px solid $gray-500;
              }

              @include media-breakpoint-up(xl) {
                column-count: 3;
              }

              .sub_item {
                display: flex;

                @include media-breakpoint-up(lg) {
                  overflow: hidden;
                  white-space: nowrap;
                }

                span {
                  display: block;
                  overflow: hidden;
                  white-space: nowrap;

                  &:first-child {
                    width: 50%;
                    flex: 0 0 50%;

                    @include media-breakpoint-up(md) {
                      width: 40%;
                      flex: 0 0 40%;
                    }
                  }
                }
              }
            }
          }
        }

        > td {
          @include media-breakpoint-up(lg) {
            overflow: hidden;
            white-space: nowrap;
          }

          background: transparent;
          border-color: $border-color;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }

    thead tr th:first-child,
    tbody tr td:first-child {
      padding-left: $card-cap-padding-x;
    }

    .dataTables_scroll {
      .dataTables_scrollBody {
        @include media-breakpoint-up(lg) {
          max-height: 60vh;
        }
      }
    }
  }

  .dataTables_scrollHead {
    table {
      thead {
        tr th {
          white-space: nowrap;
          padding-bottom: $btn-padding-y * 2;
        }
      }
    }
  }

  .dataTables_length {
    label {
      font-size: 0;

      select {
        font-size: $font-size-base;
        padding: $form-select-padding-y $form-select-padding-x;
      }
    }
  }

  .dataTables_paginate {
    a {
      padding: $btn-padding-y $btn-padding-x / 2;
      border-radius: $btn-border-radius;
      border: none;

      &.current,
      &.current:not(.disabled),
      &:hover:not(.disabled) {
        background: $primary;
        color: $white !important;
      }
    }
  }

  .dataTables_info {
    padding-right: $btn-padding-x !important;
  }

  #dataTable_processing {
    display: none !important;

    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    background: rgba($white, 0.9);
    z-index: 1;

    > div {
      display: none;

      &.loader_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}

td.edit {
  width: 48px !important;
  padding: 0 !important;
  text-align: left;

  &:hover {
    &:before {
      // background-color: $primary;
      // color: $white;

      tr.dt-hasChild & {
        // background-color: $white;
        // color: $primary;
      }
    }
  }

  &:before {
    // content: "\F5D3";
    content: "\F5D4";
    display: inline-block;
    font-family: "bootstrap-icons", sans-serif !important;
    vertical-align: -0.125em;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
  }
}
