.brand {
  max-width: 100%;

  img {
    width: 150px;
    max-width: 100%;
  }
}

#navBar {
  transition: all 0.2s ease-in;
  width: 220px;
  flex: 0 0 220px;

  @media (max-width: 992px) {
    /* Adattamento per dispositivi più piccoli */
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background: #fff;
    z-index: 10;
    transform: translateX(-100%);

    &.active {
      transform: translateX(0);
    }
  }

  .accordion {
    * {
      border-radius: 0 !important;
      box-shadow: none !important;
      outline: none !important;
    }

    .accordion-button:not(.collapsed) {
      background-color: white;
    }

    .accordion-button::after {
      background-image: url("https://icons.getbootstrap.com/assets/icons/plus.svg");
      transition: all 300ms ease-in-out;
    }

    .accordion-button:not(.collapsed)::after {
      transform: rotate(45deg);
    }

    .accordion-item {
      border-right: 0;
      border-left: 0;

      .accordion-header {
        border-left: 6px solid #ddd; /* Colore del bordo di default */
      }

      &.item-active {
        .accordion-header {
          border-left: 6px solid rgba($primary, 1); /* Bordo rosso per elemento attivo */
        }

        .item-active {
          font-weight: bold; /* Grassetto per gli elementi attivi nel sottomenu */
        }
      }
    }

    .accordion-body {
      background-color: #f8f9fa; /* Leggero sfondo grigio per il corpo dell'accordion */
    }

    .no-intern-items .accordion-button::after {
      display: none;
    }

    ul {
      margin-bottom: 0;
    }
  }
}
