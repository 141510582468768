html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

.form-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: block;
  margin: 0;
}

.form-switch {
  padding-left: 3rem;
}

.bordered {
  border: 1px solid #5a5a5a;
  padding: 10px;
}

a:-webkit-any-link {
  text-decoration: none;
  color: $dark;
}

.cursor-pointer {
  cursor: pointer;
}

.input_error {
  outline: 2px solid red;
  outline-offset: 2px;
  border-radius: $border-radius;
}

#main_container {
  @include media-breakpoint-up(lg) {
    width: calc(100% - 220px);
    flex: 0 0 calc(100% - 220px);
  }
}

.separator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.separator::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 100%;
  background-color: #ccc; /* Colore della linea */
  z-index: -1;
}

.card {
  margin: 1rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 15px;
  transition:
    color 0.15s ease,
    border-color 0.15s ease;
  max-width: 300px;
}

.card:hover,
.card:focus,
.card:active {
  color: #8f0024;
  border-color: #8f0024;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.custom-select option:hover,
.custom-select option:focus,
.custom-select option:active {
  background-color: #8f0024 !important;
  color: white !important;
}

.form-control.is-invalid {
  border-color: red;
}

.inline-attivita-off-container {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.inline-attivita-off-container .row {
  margin-left: 0;
  margin-right: 0;
}

.inline-attivita-off-container .btn-outline-primary {
  margin-right: 5px;
}

.inline-attivita-off-container .btn-outline-danger {
  margin-right: 5px;
}
